import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Branding from "./components/Branding";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Info from "./components/Info";
import Prepare from "./components/Prepare";
import Practice from "./components/Practice";
import Quiz from "./components/Quiz";
import Result from "./components/Result";
import Learn from "./components/Learn";
import { useState } from "react";
import About from "./components/About";
import Statement from "./components/Statement";
import Essay from "./components/Essay";
import Contact from "./components/Contact";
import Update from "./components/Update";
import AllEssays from "./components/AllEssays";
import SampleEssay from "./components/SampleEssay";

function App() {
  const [result, setResult] = useState({
    questions: [],
    answers: [],
  });

  const getResultData = (value) => {
    setResult(value);
  };

  return (
    <>
      <BrowserRouter>
        <Update></Update>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Branding></Branding>}></Route>
          <Route path="/info" element={<Info></Info>}></Route>
          <Route path="/prepare" element={<Prepare></Prepare>}></Route>
          <Route path="/learn/:subject/:mode" element={<Learn></Learn>}></Route>
          <Route path="/practice" element={<Practice></Practice>}></Route>
          <Route
            path="/quiz/:mode/:n/:subject"
            element={<Quiz transport={getResultData}></Quiz>}
          ></Route>
          <Route
            path="/result"
            element={<Result resultData={result}></Result>}
          ></Route>
          <Route path="/about" element={<About></About>}></Route>
          <Route path="/allessays" element={<AllEssays></AllEssays>}></Route>
          <Route path="/sample/:id" element={<SampleEssay></SampleEssay>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/essay" element={<Essay></Essay>}></Route>
          <Route path="/statement" element={<Statement></Statement>}></Route>
          {/* <Route path="/privacy" element={<Privacy></Privacy>}></Route>
      <Route path="/terms" element={<Terms></Terms>}></Route>
      <Route path="/copyrights" element={<Copy></Copy>}></Route> */}
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </>
  );
}

export default App;
