import styled from "styled-components";

const Contact = () => {
  return (
    <Wrapper
      // style={{
      //   backgroundImage:
      //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
      // }}
    >
      <h3 className="common-heading">Contact page</h3>
      <div className="container-form">
        <div className="contact-form">
          <form
            action="https://formspree.io/f/mbjvpqwd"
            method="POST"
            className="contact-inputs"
          >
            <input
              type="text"
              name="Name"
              placeholder="Enter your name"
              required
            />
            <input
              type="email"
              name="Email"
              placeholder="Enter your email"
              required
            />
            <textarea
              name="Message"
              placeholder="Enter your message"
              cols="30"
              rows="10"
            ></textarea>
            <input className="golden btn-theme" type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 1rem 0 5rem 0;
  text-align: center;

  .common-heading {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 0rem;
    text-transform: capitalize;
  }

  .container-form {
    margin-top: 2rem;

    .contact-form {
      max-width: 50rem;
      margin: auto;

      .contact-inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        input,
        textarea {
          max-width: 50rem;
          color: black;
          padding: 1rem 1rem;
          border: 1px solid golden;
          text-transform: uppercase;
          box-shadow: 2px golden;
        }

        input[type="submit"] {
          cursor: pointer;
          transition: all 0.2s;
          font-weight: 600;
          font-size: 1.2rem;

          &:hover {
            background-color: "#fff";
            border: 1px solid "rgb(98 84 243)";
            color: "rgb(98 84 243)";
          }
        }
      }
    }
  }

  @media (max-width: 930px) {
    .contact-form {
      width: 85%;
    }
  }
`;
export default Contact;
