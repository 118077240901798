import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";

const Learn = () => {
  const { subject, mode } = useParams();
  const past = mode === "all" ? false : true;
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(null);

  const hanldePrevious = () => {
    if (page - 1 > 0) {
      const p = page;
      setPage(p - 1);
    }
  };

  const handleNext = () => {
    const max = Math.ceil(data.total / 20);
    if (page + 1 <= max) {
      const p = page;
      setPage(p + 1);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (subject) {
        setLoading(true);

        const result = await axios.get(
          `https://lawadmissiontest.com:7000/api/get?random=false&subject=${subject}&page=${page}&past=${past}`
        );
        const fetchedData = result.data;
        setData(fetchedData);
        setLoading(false);
        window.scrollTo(0, 0)

        // console.log(fetchedData);
      }
    };
    getData();
  }, [subject, page, past]);

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div
          className="outer-container"
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
          // }}
        >
          {data && (
            <div className="details">
              <p>
                Subject:{" "}
                {subject === "eng"
                  ? "English"
                  : subject === "math"
                  ? "Mathematics"
                  : subject === "pkst"
                  ? "Pakistan Studies"
                  : subject === "gk"
                  ? "General Knowledge"
                  : subject === "ist"
                  ? "Islamic Studies"
                  : "Urdu"}
              </p>
              <p>Total Questions: {data.total}</p>
              <p>Mode: {mode}</p>
            </div>
          )}
          <div className="container">
            {data &&
              data.data.map((element, index) => {
                return (
                  // <div className="question">
                  //   {
                  //     page === 1? index + 1 : page === 2? index + 20 + 1: index + 1 + (page * 20)
                  //   }
                  //   <p>{q.question}</p>
                  //   <ul>
                  //     <li>{q.option1}</li>
                  //     <li>{q.option2}</li>
                  //     <li>{q.option3}</li>
                  //     <li>{q.option4}</li>
                  //     <li>{q.correctOption}</li>
                  //   </ul>
                  // </div>
                  <div className="all">
                    <p className="question urdu" key={element._id}>
                      <span>
                        {page === 1 ? index + 1 : index + 1 + (page - 1) * 20}
                      </span>
                      {". " + element.question}
                    </p>

                    <div className="both">
                      <p className="correct urdu">{element.correctOption}</p>
                    </div>
                  </div>
                );
              })}
            {data && (
              <div className="page d-flex justify-content-between my-3">
                <button
                  className="quiz-start-btn"
                  onClick={hanldePrevious}
                  disabled={page - 1 <= 0 ? true : false}
                >
                  Previous
                </button>
                <button
                  className="quiz-start-btn"
                  onClick={handleNext}
                  disabled={
                    page + 1 > Math.ceil(data.total / 20) ? true : false
                  }
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Learn;
