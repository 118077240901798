import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const AllEssays = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await axios.get(`https://lawadmissiontest.com:7000/api/sample/get`);
      const fetchedData = result.data;
      setData(fetchedData);
      setLoading(false);

      console.log(fetchedData.data);
    };
    getData();
  }, []);

  const handleClick = (id) => {
    navigate(`/sample/${id}`);
  };

  return (
    <>
      {loading ? (<Loading></Loading>) : (
      <div className="container info">
        <h1 className="text-center mt-1">Sample Essays</h1>
        {data.map((q) => {
          return (
            <div className="question" key={q._id}>
              <div
                className="title my-3"
                onClick={() => {
                  handleClick(q._id);
                }}
                
              >
                <span dangerouslySetInnerHTML={{ __html: q.title }}></span>
              </div>
            </div>
          );
        })}
      </div>
      )}
    </>
  );
};

export default AllEssays;
