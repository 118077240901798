import React from "react";
import Services from "./Services";
// import quiz from "../images/prepare.png";

const Branding = () => {
  return (
    <div
      className="branding"
      // style={{
      //   backgroundImage:
      //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
      // }}
    >
      <div className="container">
        <div>
          <h2 className="tagline">
            Law Admission Test Preparation and Practice at one Stop!
          </h2>
          <p className="info">
            At lawadmissiontest.com, You get every tiny detail about law
            admission test along with preparation material and proper quiz to
            practice and evaluate you progress for entrance exam of law college
            and much more....
          </p>
        </div>
        <Services></Services>
      </div>
    </div>
  );
};

export default Branding;
