import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";


const Essay = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const result = await axios.get(
          "https://lawadmissiontest.com:7000/api/essay/get"
        );
        // console.log(result.data)
        const fetchedData = result.data;

        setData(fetchedData.content);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      className="info pt-2 pb-5"
      // style={{
      //   backgroundImage:
      //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
      // }}
    >
      {loading ? (
        <Loading></Loading>
      ) : (
        <div
          className="container info my-5"
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
      )}
    </div>
  );
};

export default Essay;
