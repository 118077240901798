import React from "react";

const Loading = () => {
  return (
    <div className="loadigContainer">
      <div className="loadingio-spinner-gear-7ol4fvc14z9">
        <div className="ldio-ny2dr95iihg">
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
