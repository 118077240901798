import React from 'react'
// import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='footerMain'>
      <div className="sections">
        <a href='/terms'><p> Terms of Use </p></a>
        <a href='/privacy'><p>Privacy Policy</p></a>
        <p> Powered by <b>Wakalat Online</b> </p>
        <p> CopyRights Reserved &copy; 2024  </p>
        
      </div>
      <div className="socialIcons">
      <a href='https://whatsapp.com/channel/0029VaCCAMj2P59mLBsRIQ2p'><i className="fa-brands fa-whatsapp"></i></a>
      <a href='https://www.facebook.com/WakalatOnline?mibextid=ZbWKwL'><i className="fa-brands fa-facebook"></i></a>
      <a href='https://x.com/Wakalat0nline?s=20'><i className="fa-brands fa-x-twitter"></i></a>
      <a href='https://instagram.com/wakalat.online?igshid=YTQwZjQ0NmI0OA'><i className="fa-brands fa-instagram"></i></a>
      <a href='https://www.linkedin.com/company/wakalat-online/'><i className="fa-brands fa-linkedin"></i></a>
      </div>
    </div>
  )
}
