import React, { useReducer, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
const Prepare = () => {
  const navigate = useNavigate();
  const configReducer = (state,action)=>{
    switch (action.type) {
      case 'subject':
        return {
          ...state,
          subject: action.value,
        }
      case 'mode':
        return {
          ...state,
          mode: action.value,
        }
      default:
        return state

    }
  }
  const [status,setStatus] = useState(true);
  const [configuration,setConfiguration] = useReducer(configReducer,{
    subject: null,
    mode: null
  })

  const handleValidate = ()=>{
    if(configuration.subject && configuration.mode){
      navigate(`/learn/${configuration.subject}/${configuration.mode}`)
    }else {
      setStatus(false)
    }
  }
  return (
    <div
      className="practice-contianer"
      // style={{
      //   backgroundImage:
      //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
      // }}
    >
      <div className="practice">
      <h1>Configure Preparation Settings</h1>

        <div className="configuration">
          <div className="subject">
            <p>Choose a Subject:</p>
            <div className="subjects">
                <ul>
                    <li className={configuration.subject === 'eng'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'eng'})}}>English <TiTick className={configuration.subject === 'eng'?'':'none'} /></li>
                    <li className={configuration.subject === 'urdu'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'urdu'})}}>Urdu <TiTick className={configuration.subject === 'urdu'?'':'none'} /></li>
                    <li className={configuration.subject === 'pkst'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'pkst'})}}>Pakistan Studies <TiTick className={configuration.subject === 'pkst'?'':'none'} /></li>
                    <li className={configuration.subject === 'ist'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'ist'})}}>Islamic Studies <TiTick className={configuration.subject === 'ist'?'':'none'} /></li>
                    <li className={configuration.subject === 'gk'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'gk'})}}>General Knowledge <TiTick className={configuration.subject === 'gk'?'':'none'} /></li>
                    <li className={configuration.subject === 'math'?'selected':''} onClick={()=>{setConfiguration({type:'subject',value:'math'})}}>Mathematics <TiTick className={configuration.subject === 'math'?'':'none'}/></li>
                </ul>
            </div>
          </div>
          <div className="mode">
            <p>Select a mode:</p>
            <div className="modes">
                <ul>
                    <li className={configuration.mode === 'all'?'selected':''} onClick={()=>{setConfiguration({type:'mode',value:'all'})}}>All Questions<TiTick className={configuration.mode === 'all'?'':'none'} /></li>
                    <li className={configuration.mode === 'past'?'selected':''} onClick={()=>{setConfiguration({type:'mode',value:'past'})}}>Past Papers<TiTick className={configuration.mode === 'past'?'':'none'} /></li>
                </ul>
            </div>
          </div>
        </div>
        <div className={!status?'error':'none'}>
          <p>Please configure all options to move forward!</p>
        </div>
        <button onClick={handleValidate} className="quiz-start-btn">Start Learning</button>
      </div>
    </div>
  );
};

export default Prepare;
