import axios from "axios";
import React, { useEffect, useState } from "react";

const Update = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get("https://lawadmissiontest.com:7000/api/update/get");
      setData(result.data.content)
    };
    getData();
  }, []);

  return <>{data && <div className="latest-update" dangerouslySetInnerHTML={{ __html: data }}></div>}</>;
};

export default Update;
