import React, { useState } from "react";
import logo from "../images/logo192.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleMobile = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <header className={open ? "header-open" : ""}>
      <div className={open ? "relative logo" : "logo"}>
        <NavLink
          className="nav-link  text-decoration-none  fw-bold "
          aria-current="page"
          to="/"
        >
          <img src={logo} alt="logo" />
        </NavLink>
      </div>
      <div className="options">
        <ul className={open ? "show" : ""}>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/info"
            >
              Info
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/essay"
            >
              Essay
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/statement"
            >
              Statement
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/prepare"
            >
              Preparation
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link    "
              aria-current="page"
              to="/practice"
            >
              Practice
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/allessays"
            >
              Sample Essays
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/contact"
            >
              Contact Us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link  text-decoration-none  fw-bold "
              aria-current="page"
              to="/about"
            >
              About Us
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={open ? "relative logo" : "logo"}>
        <div className="burger-menu" onClick={handleMobile}>
          <div className="line-menu first-line"></div>
          <div className="line-menu"></div>
          <div className="line-menu last-line"></div>
        </div>
      </div>
    </header>
    // <nav className="navbar navbar-expand-lg border-bottom border-dotted border-2 border-black" style={{
    //   backgroundColor:
    //     "#5df4f2",
    // }}>
    //   <div className="container-fluid">
    //     <NavLink className="navbar-brand me-3" to="/">
    //       <img width={70+'px'} src={logo} alt="logo here" />
    //     </NavLink>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       data-bs-toggle="collapse"
    //       data-bs-target="#navbarSupportedContent"
    //       aria-controls="navbarSupportedContent"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
    //         <li className="nav-item fs-4 mx-2 ">
    //           <NavLink className="nav-link  text-decoration-none  fw-bold " aria-current="page" to="/">
    //             Home
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink className="nav-link  text-decoration-none  fw-bold " aria-current="page" to="/info">
    //             Info
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink className="nav-link  text-decoration-none  fw-bold " aria-current="page" to="/essay">
    //             Essay
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink
    //             className="nav-link  text-decoration-none  fw-bold "
    //             aria-current="page"
    //             to="/statement"
    //           >
    //             Statement
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink
    //             className="nav-link  text-decoration-none  fw-bold "
    //             aria-current="page"
    //             to="/prepare"
    //           >
    //             Preparation
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink
    //             className="nav-link  text-decoration-none  fw-bold "
    //             aria-current="page"
    //             to="/practice"
    //           >
    //             Practice
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink
    //             className="nav-link  text-decoration-none  fw-bold "
    //             aria-current="page"
    //             to="/contact"
    //           >
    //             Contact Us
    //           </NavLink>
    //         </li>
    //         <li className="nav-item fs-4 mx-2">
    //           <NavLink className="nav-link  text-decoration-none  fw-bold " aria-current="page" to="/about">
    //             About Us
    //           </NavLink>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
  );
};

export default Navbar;
