import React from "react";
import { PiReadCvLogoBold } from "react-icons/pi";
import { FaPenNib } from "react-icons/fa";
import { CiKeyboard } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Services = () => {
    const navigate = useNavigate()
  return (
    <div className="services" >
      <div className="option" onClick={()=>{navigate('/info')}}>
        <PiReadCvLogoBold />
        <hr/>
        <p>Information</p>
      </div>
      <div className="option" onClick={()=>{navigate('/prepare')}}>
        <FaPenNib />
        <hr/>
        <p>Prepare </p>
      </div>
      <div className="option" onClick={()=>{navigate('/practice')}}>
        <CiKeyboard />
        <hr/>
        <p>Practice Test</p>
      </div>
    </div>
  );
};

export default Services;
