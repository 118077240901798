import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import Loading from "./Loading";
import axios from "axios";

const Quiz = (props) => {
  const { transport } = props;
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("Next Question");
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [count, setCount] = useState(0);

  const { mode, n, subject } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(null);

  const [lastQuestionReached, setLastQuestionReached] = useState(false);


  useEffect(() => {
    const initialAnswers = Array(content.length).fill(null);
    setAnswers(initialAnswers);

    const getData = async () => {
      if (subject) {
        setLoading(true);

        const result = await axios.get(
          `https://lawadmissiontest.com:7000/api/get?random=true&subject=${subject}&n=${n}&past=${mode === 'past'?true:false}`
        );
        const fetchedData = result.data;
        setContent(fetchedData);
        setCurrentQuestion(fetchedData[0]);
        setLoading(false);

        // console.log(fetchedData.data);
      }
    };
    getData();
  }, [content.length, subject, n, mode]);


  useEffect(() => {
    if (lastQuestionReached) {
      transport({
        questions: content,
        answers: answers,
      });
      navigate("/result");
    }
  }, [lastQuestionReached, answers, content, navigate, transport]);

  // const handleNext = () => {
  //   if (selected) {
  //     if (count + 1 === content.length) {
  //       navigate("/result");
  //     } else {
  //       if (count + 2 === content.length) {
  //         console.log("It's last question.");
  //         setText("See Result");
  //       }
  //       const toChange = answers;
  //       toChange[count] = selected;
  //       setAnswers([...toChange]);
  //       setSelected(null);
  //       const current = count;
  //       setCount(current + 1);
  //       setCurrentQuestion(content[current + 1]);
  //     }
  //   } else {
  //     setError(true);
  //   }
  // };

  // const handleSkip = () => {
  //   console.log(count);
  //   console.log(count + 1 === content.length);

  //   if (count + 1 === content.length) {
  //     navigate("/result");
  //   } else {
  //     if (count + 2 === content.length) {
  //       console.log("It's last question.");
  //       setText("See Result");
  //     }
  //     const toChange = answers;
  //     toChange[count] = null;
  //     setAnswers([...toChange]);
  //     setSelected(null);
  //     const current = count;
  //     console.log(current + 1);
  //     setCount(current + 1);
  //     setCurrentQuestion(content[current + 1]);
  //   }
  // };

  const handleNext = () => {
    if (selected) {
      if (count + 1 === content.length) {
        console.log(count+1 === content.length,'I am out')
        setAnswers((prevAnswers) => {
          const toChange = [...prevAnswers]; 
          toChange[count] = selected;
          return toChange;
        });
        setLastQuestionReached(true);
      } else {
        if (count + 2 === content.length) {
          console.log("It's last question.");
          setText("See Result");
        }
        setAnswers((prevAnswers) => {
          const toChange = [...prevAnswers]; 
          toChange[count] = selected;
          return toChange;
        });
        setSelected(null);
        const current = count;
        setCount(current + 1);
        setCurrentQuestion(content[current + 1]);
      }
    } else {
      setError(true);
    }
  };

  // const handleNext = () => {
  //   console.log(count, '======' , answers)
  //   if (selected) {
  //     if (count + 1 === content.length) {
  //       setAnswers((prevAnswers) => {
  //         const toChange = [...prevAnswers];
  //         toChange[count] = selected;
  //         return toChange;
  //       });
  //     } else {
  //       setAnswers((prevAnswers) => {
  //         const toChange = [...prevAnswers];
  //         toChange[count] = selected;
  //         return toChange;
  //       });
  //       setSelected(null);
  //       const current = count;
  //       setCount(current + 1);
  //       setCurrentQuestion(content[current + 1]);
  //     }
  //   } else {
  //     setError(true);
  //   }
  // };

  // useEffect(() => {
  //   if (count === content.length) {
  //     transport({
  //       questions: content,
  //       answers: answers,
  //     });
  //     navigate("/result");
  //   }
  // }, [answers]);

  const handleSkip = () => {
    // console.log(count);
    if (count + 1 === content.length) {
      transport({
        questions: content,
        answers: answers,
      });
      navigate("/result");
    } else {
      if (count + 2 === content.length) {
        console.log("It's last question.");
        setText("See Result");
      }
      setAnswers((prevAnswers) => {
        const toChange = [...prevAnswers]; // Create a new array
        toChange[count] = null;
        return toChange;
      });
      setSelected(null);
      const current = count;
      setCount(current + 1);
      setCurrentQuestion(content[current + 1]);
    }
  };

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div
          className="quiz"
          // style={{
          //   backgroundImage:
          //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
          // }}
        >
          <div className="quiz-container container pb-5">
            <div className="details">
              <p>
                Subject:{" "}
                {subject === "eng"
                  ? "English"
                  : subject === "math"
                  ? "Mathematics"
                  : subject === "pkst"
                  ? "Pakistan Studies"
                  : subject === "gk"
                  ? "General Knowledge"
                  : subject === "istd"
                  ? "Islamic Studies"
                  : "Urdu"}
              </p>
              <p>
                Question No. {count + 1} of {n}
              </p>
              <p>Mode: {mode === "all" ? "All" : "Past"}</p>
            </div>
            <p className="quiz-question">{currentQuestion.question}</p>
            <div className="quiz">
              <ul className="urdu">
                <li
                  className={
                    selected === currentQuestion.option1 ? "selected" : ""
                  }
                  onClick={() => {
                    handleClick(currentQuestion.option1);
                  }}
                >
                  <TiTick
                    className={
                      selected === currentQuestion.option1 ? "" : "none"
                    }
                  />
                  {currentQuestion.option1}
                </li>
                <li
                  className={
                    selected === currentQuestion.option2 ? "selected" : ""
                  }
                  onClick={() => {
                    handleClick(currentQuestion.option2);
                  }}
                >
                  <TiTick
                    className={
                      selected === currentQuestion.option2 ? "" : "none"
                    }
                  />
                  {currentQuestion.option2}
                </li>
                <li
                  className={
                    selected === currentQuestion.option3 ? "selected" : ""
                  }
                  onClick={() => {
                    handleClick(currentQuestion.option3);
                  }}
                >
                  <TiTick
                    className={
                      selected === currentQuestion.option3 ? "" : "none"
                    }
                  />
                  {currentQuestion.option3}
                </li>
                <li
                  className={
                    selected === currentQuestion.option4 ? "selected" : ""
                  }
                  onClick={() => {
                    handleClick(currentQuestion.option4);
                  }}
                >
                  <TiTick
                    className={
                      selected === currentQuestion.option4 ? "" : "none"
                    }
                  />
                  {currentQuestion.option4}
                </li>
              </ul>
            </div>
            {error && (
              <div className="error">
                Please Select an Option or <b>Skip Question</b>
              </div>
            )}
            <div className="controls">
              <button className="quiz-start-btn" onClick={handleSkip}>
                Skip Question
              </button>
              <button className="quiz-start-btn" onClick={handleNext}>
                {text}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Quiz;
