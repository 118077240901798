import React, { useEffect, useState } from "react";
import { MdCelebration } from "react-icons/md";

const Result = (props) => {
  const { questions, answers } = props.resultData;
  const [number, setNumber] = useState(null);

  useEffect(() => {
    const compileResult = () => {
      let numbers = 0;
      questions.forEach((element, index) => {
        if (answers[index] !== null) {
          if (element.correctOption.trim() === answers[index].trim()) {
            numbers++;
          }
        }
      });
      setNumber(numbers);
    };
    compileResult();
  }, [answers, questions]);

  return (
    <div
      className="outer-container"
      // style={{
      //   backgroundImage:
      //     "linear-gradient(to top, #61bac6, #60c8d2, #5ed7de, #5de5e8, #5df4f2)",
      // }}
    >
      <div className="container">
        <div className="result">
          <div className="first">
            <MdCelebration />

            <h3>Result</h3>
          </div>
          <div className="first">
            <p>
              You Got
              <span className="number">
                {number}/{questions.length}
              </span>
            </p>
            <p>
              {" "}
              Percentage:{" "}
              <span className="number">{`${
                (number / questions.length).toFixed(2) * 100
              }%`}</span>
            </p>
          </div>
          <div className="second">
            <p className="total">
              Total Questions:{" "}
              <span className="number">{questions.length}</span>
            </p>
            <p className="Correct">
              Correct Answers: <span className="number">{number}</span>{" "}
            </p>
            <p className="Wrong">
              Wrong Answers:{" "}
              <span className="number">{questions.length - number} </span>
            </p>
          </div>
        </div>

        {questions.map((element, index) => {
          return (
            <div className="all">
              <p className="question urdu">
                {index + 1 + ". " + element.question}
              </p>
              {element.correctOption === answers[index] ? (
                <div className="both">
                  <p className="correct urdu">{element.correctOption}</p>
                </div>
              ) : (
                <div className="both">
                  <p className="wrong urdu">
                    {answers[index] === null ? "Skipped" : answers[index]}
                  </p>
                  <p className="correct urdu">{element.correctOption}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Result;
